import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";

const CustomModal = ({ show, handleClose, configData, isEdit, handleSave }) => {
  const initialFormState = {
    referral_name: "",
    buy_token: "",
    sell_token: "",
    chain_id: "",
    promo_code: "",
    isTelegram: false,
    telegramGroup: {
      name: "",
      title: "",
      id: "",
    },
    revenue: [], // Ensure revenue is always an array
  };

  const [tokens, setTokens] = useState([]);
  const [chains, setChains] = useState([]);
  const [filteredTokens, setFilteredTokens] = useState([]);

  const [formData, setFormData] = useState(initialFormState);

  const fetchChains = async () => {
    try {
      const response = await pineappleDexService.getChains();

      if (response?.data?.success) {
        setChains(response.data.chains);
      }
    } catch (error) {
      console.error("Error fetching chains:", error);
    }
  };

  const fetchTokens = async (params) => {
    try {
      const response = await pineappleDexService.getAllTokensList(params);
      console.log("res", response);
      if (response?.data?.success) {
        setTokens(response.data.tokens);
        setFilteredTokens(response.data.tokens);
      }
    } catch (error) {
      console.error("Error fetching tokens:", error);
    }
  };

  const handleChainSelect = async (chain) => {
    setFormData({ ...formData, chain_id: chain });
    const tokensResponse = await fetchTokens({ chainid: chain }); // Fetch tokens based on selected chain
    if (tokensResponse && tokensResponse.length > 0) {
        setFormData({ ...formData, buy_token: tokensResponse[0].address, sell_token: tokensResponse[1]?.address || "" }); // Set first two tokens as default
    }
  };

  const [newRevenue, setNewRevenue] = useState({
    referrer_name: "",
    percent_share: "",
    wallet_address: "",
  });

  const [editingRevenueIndex, setEditingRevenueIndex] = useState(null);
  const [editRevenue, setEditRevenue] = useState({
    referrer_name: "",
    percent_share: "",
    wallet_address: "",
  });

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name.includes("telegramGroup")) {
      const field = name.split(".")[1];
      setFormData((prev) => ({
        ...prev,
        telegramGroup: {
          ...prev.telegramGroup,
          [field]: value,
        },
      }));
    } else if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRevenueInputChange = (e) => {
    const { name, value } = e.target;
    setNewRevenue({
      ...newRevenue,
      [name]: value,
    });
  };

  const handleEditRevenueInputChange = (e) => {
    const { name, value } = e.target;
    setEditRevenue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateTotalShare = (revenues) => {
    // If revenues is null, undefined, or not an array, return 0
    if (!revenues || !Array.isArray(revenues)) {
      return 0;
    }
    return revenues.reduce(
      (sum, rev) => sum + Number(rev.percent_share || 0),
      0
    );
  };

  const handleAddRevenue = () => {
    if (
      !newRevenue.referrer_name ||
      !newRevenue.percent_share ||
      !newRevenue.wallet_address
    ) {
      swal({
        icon: "error",
        text: "Please fill all revenue fields",
        button: "OK",
      });
      return;
    }

    const newShare = Number(newRevenue.percent_share);
    const currentTotal = calculateTotalShare(formData.revenue);

    if (newShare <= 0) {
      swal({
        icon: "error",
        text: "Share percentage must be greater than 0",
        button: "OK",
      });
      return;
    }

    if (currentTotal + newShare > 100) {
      swal({
        icon: "error",
        text: "Total share percentage cannot exceed 100%",
        button: "OK",
      });
      return;
    }

    setFormData({
      ...formData,
      revenue: [...formData.revenue, { ...newRevenue }],
    });

    // Reset new revenue form
    setNewRevenue({
      referrer_name: "",
      percent_share: "",
      wallet_address: "",
    });
  };

  const handleRemoveRevenue = (index) => {
    const updatedRevenue = formData.revenue.filter((_, i) => i !== index);
    setFormData({
      ...formData,
      revenue: updatedRevenue,
    });
  };

  const handleEditRevenue = (index) => {
    setEditingRevenueIndex(index);
    setEditRevenue({ ...formData.revenue[index] });
  };

  const handleUpdateRevenue = () => {
    if (
      !editRevenue.referrer_name ||
      !editRevenue.percent_share ||
      !editRevenue.wallet_address
    ) {
      swal({
        icon: "error",
        text: "Please fill all revenue fields",
        button: "OK",
      });
      return;
    }

    const newShare = Number(editRevenue.percent_share);
    const currentTotal =
      calculateTotalShare(formData.revenue) -
      Number(formData.revenue[editingRevenueIndex].percent_share);

    if (newShare <= 0) {
      swal({
        icon: "error",
        text: "Share percentage must be greater than 0",
        button: "OK",
      });
      return;
    }

    if (currentTotal + newShare > 100) {
      swal({
        icon: "error",
        text: "Total share percentage cannot exceed 100%",
        button: "OK",
      });
      return;
    }

    const updatedRevenue = [...formData.revenue];
    updatedRevenue[editingRevenueIndex] = { ...editRevenue };

    setFormData({
      ...formData,
      revenue: updatedRevenue,
    });

    // Reset form and editing state
    setEditRevenue({
      referrer_name: "",
      percent_share: "",
      wallet_address: "",
    });
    setEditingRevenueIndex(null);
  };

  const handleCancelEdit = () => {
    setEditingRevenueIndex(null);
    setEditRevenue({
      referrer_name: "",
      percent_share: "",
      wallet_address: "",
    });
  };

  useEffect(() => {
    console.log("formData", formData);
    console.log("configData", configData);
    fetchTokens();
    fetchChains();
    if (configData) {
        const updatedFormData = {
            ...initialFormState,
            ...configData,
            telegramGroup: {
                ...initialFormState.telegramGroup,
                ...(configData.telegramGroup || {}),
            },
            revenue: Array.isArray(configData.revenue) ? configData.revenue : [],
        };
        setFormData({
            ...updatedFormData
        });
        if (configData.chain_id) {
            fetchTokens({ chainid: configData.chain_id }).then((tokensResponse) => {
                if (tokensResponse && tokensResponse.length > 0) {
                    setFormData({
                        ...updatedFormData,
                        buy_token: tokensResponse[0].address,
                        sell_token: tokensResponse[1]?.address || ""
                    });
                }
            });
        }
    } else {
        setFormData(initialFormState);
    }
  }, [configData]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    if (!formData.referral_name || !formData.promo_code) {
      swal({
        icon: "error",
        text: "Please fill in Referral Name and Promo Code",
        button: "OK",
      });
      return;
    }

    // Validate revenue shares
    if (!formData.revenue || formData.revenue.length === 0) {
      swal({
        icon: "error",
        text: "Please add at least one revenue share",
        button: "OK",
      });
      return;
    }

    // Validate total revenue share percentage
    const totalShare = calculateTotalShare(formData.revenue);
    if (totalShare !== 100) {
      swal({
        icon: "error",
        text: "Total revenue share must equal 100%",
        button: "OK",
      });
      return;
    }
    // Call the handleSave prop with the form data
    handleSave(formData);
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit Referral" : "Add Referral"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Referral Name*</Form.Label>
            <Form.Control
              type="text"
              name="referral_name"
              value={formData.referral_name}
              onChange={handleInputChange}
              placeholder="Enter referral name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Promo Code*</Form.Label>
            <Form.Control
              type="text"
              name="promo_code"
              value={formData.promo_code}
              onChange={handleInputChange}
              placeholder="Enter promo code"
              required
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Chain ID</Form.Label>
            <Form.Control
              as="select"
              value={formData.chain_id || ""}
              onChange={(e) => {
                handleChainSelect(e.target.value);
              }}
            >
              <option value="">Select Chain ID</option>
              {chains.map((chain) => (
                <option key={chain._id} value={chain.chainId}>
                  {chain.displayName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Buy Token</Form.Label>
            <Form.Control
              as="select"
              value={formData.buy_token || ""}
              onChange={(e) => {
                setFormData({ ...formData, buy_token: e.target.value });
              }}
            >
              <option value="">Select Buy Token</option>
              {filteredTokens.map((token) => (
                <option key={token._id} value={token.address}>
                  {token.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group>
            <Form.Label>Sell Token</Form.Label>
            <Form.Control
              as="select"
              value={formData.sell_token || ""}
              onChange={(e) => {
                setFormData({ ...formData, sell_token: e.target.value });
              }}
            >
              <option value="">Select Sell Token</option>
              {filteredTokens.map((token) => (
                <option key={token._id} value={token.address}>
                  {token.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name="isTelegram"
              label="Is Telegram Group"
              checked={formData.isTelegram}
              onChange={handleInputChange}
            />
          </Form.Group>

          {formData.isTelegram && (
            <div className="telegram-group-section mb-4">
              <h5 className="mb-3">Telegram Group Details</h5>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="telegramGroup.name"
                      value={formData.telegramGroup.name}
                      onChange={handleInputChange}
                      placeholder="Enter group name"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="telegramGroup.title"
                      value={formData.telegramGroup.title}
                      onChange={handleInputChange}
                      placeholder="Enter group title"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>ID (Optional)</Form.Label>
                    <Form.Control
                      type="text"
                      name="telegramGroup.id"
                      value={formData.telegramGroup.id}
                      onChange={handleInputChange}
                      placeholder="Enter group ID"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          )}

          <div className="revenue-section mb-4">
            <h5>Revenue Sharing</h5>
            <p className="text-muted">
              Total Share: {calculateTotalShare(formData.revenue)}%
            </p>

            {Array.isArray(formData.revenue) &&
              formData.revenue.map((rev, index) => (
                <div
                  key={index}
                  className="revenue-item border p-3 mb-2 rounded"
                >
                  {editingRevenueIndex === index ? (
                    <div>
                      <Row>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Referrer Name</Form.Label>
                            <Form.Control
                              type="text"
                              name="referrer_name"
                              value={editRevenue.referrer_name}
                              onChange={handleEditRevenueInputChange}
                              placeholder="Enter referrer name"
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className="mb-3">
                            <Form.Label>Share Percentage</Form.Label>
                            <Form.Control
                              type="number"
                              name="percent_share"
                              value={editRevenue.percent_share}
                              onChange={handleEditRevenueInputChange}
                              placeholder="Enter share percentage"
                              min="0"
                              max="100"
                              step="0.01"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <Form.Group className="mb-3">
                            <Form.Label>Wallet Address</Form.Label>
                            <Form.Control
                              type="text"
                              name="wallet_address"
                              value={editRevenue.wallet_address}
                              onChange={handleEditRevenueInputChange}
                              placeholder="Enter wallet address"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end gap-2">
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={handleCancelEdit}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="success"
                          size="sm"
                          onClick={handleUpdateRevenue}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <strong>{rev.referrer_name}</strong> -{" "}
                        {rev.percent_share}%
                        <br />
                        <small className="text-muted">
                          {rev.wallet_address}
                        </small>
                      </div>
                      <div className="d-flex gap-2">
                        <Button
                          variant="info"
                          size="sm"
                          onClick={() => handleEditRevenue(index)}
                        >
                          <i className="fa-solid fa-pen-to-square"></i>
                        </Button>
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => handleRemoveRevenue(index)}
                        >
                          <i className="fa-solid fa-trash"></i>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              ))}

            <div className="add-revenue-form border p-3 rounded">
              <h6 className="mb-3">Add Revenue Share</h6>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Referrer Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="referrer_name"
                      value={newRevenue.referrer_name}
                      onChange={handleRevenueInputChange}
                      placeholder="Enter referrer name"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Share Percentage</Form.Label>
                    <Form.Control
                      type="number"
                      name="percent_share"
                      value={newRevenue.percent_share}
                      onChange={handleRevenueInputChange}
                      placeholder="Enter share percentage"
                      min="0"
                      max="100"
                      step="0.01"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Wallet Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="wallet_address"
                      value={newRevenue.wallet_address}
                      onChange={handleRevenueInputChange}
                      placeholder="Enter wallet address"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-end">
                <Button variant="primary" size="sm" onClick={handleAddRevenue}>
                  Add Revenue Share
                </Button>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <Button variant="secondary" className="me-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {isEdit ? "Update" : "Create"}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomModal;
