import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Container,
  Dropdown,
  Form,
  Row,
  Table,
  Button,
} from "react-bootstrap";
import Pagination from "react-js-pagination";
import swal from "sweetalert";
import { pineappleDexService } from "../../service/api.service";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";
import { FaCopy } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { debounce } from "lodash";
import { OverLayAddressTooltip } from "../../common/Functions";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { getItem, setItem } from "../../util/localStorage";
import CustomModal from "../../common/components/Modal";
import { Modal, Tab, Tabs } from "react-bootstrap";

const Referral = () => {
  const [promotionListing, setPromotionListing] = useState([]);
  const [promotionData, setPromotionData] = useState(null);
  const [totalItems, setTotalItems] = useState();
  const [totalCount, setTotalCount] = useState();
  const [loader, setLoader] = useState(false);
  const [page, setPage] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [filter, setFilter] = useState("");

  // modal
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [search, setSearch] = useState("");
  const [searchLoader, setSearchLoader] = useState(false);

  const [copied, setCopied] = useState(false);
  const [copiedValue, setCopiedValue] = useState(null);

  const [highValueSorting, setHighValueSorting] = useState(null);
  const [highActivitiesSorting, setHighActivitiesSorting] = useState(null);
  const [mouseHoverAddressValue, setMouseHoverAddressValue] = useState("");
  const [copiedCode, setCopiedCode] = useState(null);
  const { state } = useLocation();

  const [referralDetail, setReferralDetail] = useState({
    referral_name: "",
    promo_code: "",
    isTelegram: false,
    telegramGroup: {
      name: "",
      title: "",
      id: "",
    },
    revenue: [],
    buy_token: "",
    sell_token: "",
    chain_id: "",
  });

  const getPromotionList = useCallback(async (pageNo, searchKey, filterKey) => {
    try {
      setLoader(true);
      const params = {
        page: Math.max(0, pageNo - 1),
        keyword: searchKey || "",
        sort: "",
        sortType: "desc",
        filter: filterKey || "",
        showAll: true,
      };

      const response = await pineappleDexService.PromotionList(params);
      if (response?.data?.success) {
        setPromotionData(response?.data || []);
        setPromotionListing(response?.data?.data || []);
        setTotalCount(response?.data?.totalCount || 0);
        setTotalItems(response?.data?.totalPromotions || 0);
      } else {
        throw new Error(
          response?.data?.message || "Failed to fetch promotions"
        );
      }
    } catch (error) {
      swal({
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        button: "OK",
      });
    } finally {
      setLoader(false);
      setSearchLoader(false);
    }
  }, []);

  useEffect(() => {
    const searchValue = getItem("promotion_list_search");
    const state = getItem("state");
    if (state === "from_user_activities_screen" && searchValue) {
      setSearch(searchValue);
      getPromotionList(1, searchValue);
    } else {
      getPromotionList(1);
    }
  }, [getPromotionList]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
    setActivePage(pageNumber);
    getPromotionList(pageNumber, search, filter);
  };

  const handleFilters = (filterVal) => {
    setFilter(filterVal);
    setPage(1);
    setActivePage(1);
    getPromotionList(1, search, filterVal);
  };

  const searchData = useCallback(debounce(getPromotionList, 1000), []);

  const handleEditModal = (referral) => {
    setIsEdit(true);
    setShowModal(true);
    console.log("edit_referral", referral);
    setReferralDetail({
      _id: referral._id || "",
      referral_name: referral.referral_name || "",
      promo_code: referral.promo_code || "",
      isTelegram: referral.isTelegram || false,
      telegramGroup: referral.telegramGroup
        ? {
            name: referral.telegramGroup.name || "",
            title: referral.telegramGroup.title || "",
            id: referral.telegramGroup.id || "",
          }
        : {
            name: "",
            title: "",
            id: "",
          },
      revenue: referral.revenue || [],
      buy_token: referral.buy_token || "",
      sell_token: referral.sell_token || "",
      chain_id: referral.chain_id || "",
    });
  };

  const handleSave = async (data) => {
    try {
      setLoader(true);
      let response;

      // Prepare the payload to match the backend expected structure
      const payload = {
        referral_name: data.referral_name,
        promo_code: data.promo_code,
        isTelegram: data.isTelegram,
        telegramGroup: data.isTelegram
          ? {
              id: data.telegramGroup.id || data.telegramGroup.group_id,
              name: data.telegramGroup.name || data.telegramGroup.group_name,
              title: data.telegramGroup.title || data.telegramGroup.group_title,
            }
          : null,
        revenue: data.revenue.map((rev) => ({
          referrer_name: rev.referrer_name,
          percent_share: rev.percent_share,
          wallet_address: rev.wallet_address,
        })),
        buy_token: data.buy_token || "",
        sell_token: data.sell_token || "",
        chain_id: data.chain_id || "",
      };

      if (isEdit) {
        // Explicitly pass the _id for update
        const updatePayload = {
          ...payload,
          _id: referralDetail._id,
        };
        response = await pineappleDexService.updateReferral(updatePayload);
      } else {
        response = await pineappleDexService.createReferral(payload);
      }

      if (response?.data?.success) {
        swal({
          icon: "success",
          text: `Referral ${isEdit ? "updated" : "created"} successfully`,
          button: "OK",
        });
        await getPromotionList(page, search, filter);
      } else {
        throw new Error(response?.data?.message || "Operation failed");
      }
    } catch (error) {
      swal({
        icon: "error",
        text: error?.response?.data?.message || error?.message,
        button: "OK",
      });
    } finally {
      setLoader(false);
      setShowModal(false);
    }
  };

  const clearState = () => {
    setReferralDetail({
      _id: "",
      referral_name: "",
      promo_code: "",
      isTelegram: false,
      revenue: [],
      telegramGroup: {
        id: "",
        name: "",
        title: "",
      },
      buy_token: "",
      sell_token: "",
      chain_id: "",
    });
  };

  const generateReferralLink = (params) => {
    return `https://app.pineappledex.com/swap?buyToken=${params.buy_token}&sellToken=${params.sell_token}&chain=${params.chain_id}&ref=${params.promo_code}`;
  };

  const handleCopyLink = (params) => {
    const link = generateReferralLink(params);
    navigator.clipboard.writeText(link);
    setCopiedCode(params);
    setTimeout(() => setCopiedCode(null), 2000); // Reset copy status after 2 seconds
    swal({
      icon: "success",
      text: "Referral link copied to clipboard!",
      button: false,
      timer: 2000,
    });
  };

  const handleCopyWallet = (wallet) => {
    navigator.clipboard.writeText(wallet);
    swal({
      icon: "success",
      text: "Wallet address copied to clipboard!",
      button: false,
      timer: 2000,
    });
  };

  const renderPromotionList = () => {
    return (
      <div className="table-responsive">
        <Table>
          <thead>
            <tr>
              <th>Referral Name</th>
              <th>Promo Code</th>
              <th>Referrers</th>
              <th>Commission (%)</th>
              <th>Wallet Address</th>
              <th>Telegram Group</th>
              <th>Buy Token</th>
              <th>Sell Token</th>
              <th>Chain ID</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {promotionListing.map((referral) => (
              <tr key={referral._id}>
                <td>{referral.referral_name}</td>
                <td>
                  <div className="d-flex align-items-center">
                    {referral.promo_code}
                    <Button
                      variant="link"
                      className="p-0 ms-2"
                      onClick={() => handleCopyLink(referral)}
                    >
                      <i
                        className={`fa-solid ${
                          copiedCode === referral.promo_code
                            ? "fa-check text-success"
                            : "fa-copy text-primary"
                        }`}
                      ></i>
                    </Button>
                  </div>
                </td>
                <td>
                  {referral.revenue.map((rev, index) => (
                    <span
                      key={index}
                      className="badge bg-info me-1 mb-1"
                      style={{ display: "inline-block" }}
                    >
                      {rev.referrer_name}
                    </span>
                  ))}
                </td>
                <td>
                  {referral.revenue.map((rev, index) => (
                    <span
                      key={index}
                      className="badge bg-warning me-1 mb-1"
                      style={{ display: "inline-block" }}
                    >
                      {rev.percent_share}%
                    </span>
                  ))}
                </td>
                <td>
                  {referral.revenue.map((rev, index) => (
                    <div key={index} className="d-flex align-items-center mb-1">
                      {rev.wallet_address ? (
                        <>
                          <span className="me-2">
                            {rev.wallet_address.slice(0, 6)}...
                            {rev.wallet_address.slice(-4)}
                          </span>
                          <Button
                            variant="link"
                            className="p-0"
                            onClick={() => handleCopyWallet(rev.wallet_address)}
                          >
                            <i className="fa-solid fa-copy text-primary"></i>
                          </Button>
                        </>
                      ) : (
                        <span className="badge bg-secondary">No Wallet</span>
                      )}
                    </div>
                  ))}
                </td>
                <td>
                  {referral.isTelegram ? (
                    <div>
                      <span className="badge bg-success me-1">
                        Name: {referral.telegramGroup.name}
                      </span>
                      <span className="badge bg-primary me-1">
                        Title: {referral.telegramGroup.title}
                      </span>
                      {referral.telegramGroup.id && (
                        <span className="badge bg-secondary">
                          ID: {referral.telegramGroup.id}
                        </span>
                      )}
                    </div>
                  ) : (
                    <span className="badge bg-danger">No Telegram Group</span>
                  )}
                </td>

                <td>
                  {referral.buy_token && (
                    <span className="badge bg-secondary me-1">
                      {referral.buy_token.slice(0, 12)}
                    </span>
                  )}
                </td>
                <td>
                  {referral.sell_token && (
                    <span className="badge bg-secondary me-1">
                      {referral.sell_token.slice(0, 12)}
                    </span>
                  )}
                </td>
                <td>
                  {referral.chain_id && (
                    <span className="badge bg-secondary me-1">
                      {referral.chain_id}
                    </span>
                  )}
                </td>
                <td>{moment(referral.createdAt).format("DD-MM-YYYY")}</td>
                <td>{moment(referral.createdAt).format("DD-MM-YYYY")}</td>
                <td>
                  <div className="d-flex">
                    <div
                      className="view-btn-main"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleEditModal(referral)}
                    >
                      <i className="fa-solid fa-eye"></i>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <>
      <div className="dashboard-main-area">
        <Container fluid>
          <div className="dashboard-area-heading management-heading">
            <Row className="margin50">
              <Col md={3}>
                {loader ? (
                  <ShimmerThumbnail className="card-shimmer" />
                ) : (
                  <div className="card-infor-box">
                    <div className="main-icon-1">
                      <i className="fa-solid fa-cart-shopping"></i>
                    </div>
                    <div>
                      <h5>Total Referrals</h5>
                      <h3>
                        {promotionData && promotionData.totalPromotions
                          ? promotionData.totalPromotions
                          : "0"}
                      </h3>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={3}>
                {loader ? (
                  <ShimmerThumbnail className="card-shimmer" />
                ) : (
                  <div className="card-infor-box">
                    <div className="main-icon-1">
                      <i className="fa-solid fa-coins"></i>
                    </div>
                    <div>
                      <h5>Filtered Referrals</h5>
                      <h3>
                        {promotionData && promotionData.totalCount
                          ? promotionData.totalCount
                          : "0"}
                      </h3>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={3}>
                {loader ? (
                  <ShimmerThumbnail className="card-shimmer" />
                ) : (
                  <div className="card-infor-box">
                    <div className="main-icon-1">
                      <i className="fa-solid fa-shuffle"></i>
                    </div>
                    <div>
                      <h5>Total Referrals Used</h5>
                      <h3>
                        {promotionData && promotionData.totalCount
                          ? promotionData.totalCount
                          : "0"}
                      </h3>
                    </div>
                  </div>
                )}
              </Col>
              <Col md={3}>
                {loader ? (
                  <ShimmerThumbnail className="card-shimmer" />
                ) : (
                  <div className="card-infor-box">
                    <div className="main-icon-1">
                      <i className="fa-solid fa-road"></i>
                    </div>
                    <div>
                      <h5>Referrals in last 24 Hours</h5>
                      <h3>
                        {promotionData && promotionData.last24Huser
                          ? promotionData.last24Huser
                          : "0"}
                      </h3>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="row justify-content-center align-items-center mb-2">
              <Col md={12}>
                <div className="d-flex justify-content-between">
                  <div className="d-flex ">
                    <h2 className="align-self-center m-0">Referrals</h2>
                  </div>

                  <div className="d-flex align-items-center">
                    <Button
                      variant="primary"
                      className="me-3"
                      onClick={() => {
                        setIsEdit(false);
                        setShowModal(true);
                      }}
                    >
                      Create Referral
                    </Button>

                    <div className="search-area main-search-area">
                      <div className="d-flex align-items-center justify-content-between">
                        <form
                          autoComplete="off"
                          onSubmit={(event) => {
                            event.preventDefault();
                          }}
                        >
                          <div className="position-relative width-inner-main">
                            <input
                              type="text"
                              value={search}
                              className="form-control inner-input"
                              placeholder="Search by wallet address..."
                              onChange={(e) => (
                                setSearchLoader(true),
                                searchData(page, e.target.value, filter),
                                setSearch(e.target.value),
                                setHighValueSorting(""),
                                setHighActivitiesSorting("")
                              )}
                              onKeyDown={(e) => {
                                if (!e.target.value && e.code === "Space") {
                                  e.preventDefault();
                                }
                              }}
                            />
                            {searchLoader ? (
                              <span className="loader-search"></span>
                            ) : (
                              <i
                                className="fa fa-search"
                                aria-hidden="true"
                              ></i>
                            )}
                            <i
                              className="fa fa-close d-none"
                              aria-hidden="true"
                            ></i>
                          </div>
                        </form>
                        <div className="select-days position-relative">
                          <Form.Select
                            aria-label="Default select example"
                            value={filter}
                            onChange={(e) => handleFilters(e.target.value)}
                          >
                            <option value="">All</option>
                            <option value="24h">Last 24 Hours</option>
                            <option value="48h">Last 48 Hours</option>
                            <option value="7d">Last 7 Days</option>
                            <option value="15d">Last 15 Days</option>
                            <option value="30d">Last 30 Days</option>
                          </Form.Select>
                          <i className="fa-solid fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-4 justify-content-center">
              <Col md={12}>
                <div className="audit-request-box">
                  {loader ? (
                    <>
                      {[...Array(10)].map((_, index) => (
                        <tr key={index}>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                          <td>
                            <ShimmerThumbnail />
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : promotionListing && promotionListing?.length > 0 ? (
                    renderPromotionList()
                  ) : (
                    <tr className="no-dat-found-box">
                      <td colSpan={10}>
                        <img
                          src={
                            require("../../assets/images/no-data.svg").default
                          }
                          alt="no-data-image"
                        />
                        <h3 className="text-center text-light">
                          No data found
                        </h3>
                      </td>
                    </tr>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      {promotionListing.length > 0 && totalItems > 10 && !loader && (
        <div className="d-flex justify-content-center mt-3">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={totalItems}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="Previous"
            nextPageText="Next"
            firstPageText="First"
            lastPageText="Last"
          />
        </div>
      )}
      <CustomModal
        show={showModal}
        handleClose={() => {
          setShowModal(false);
          clearState();
        }}
        configData={referralDetail}
        isEdit={isEdit}
        handleSave={handleSave}
      />
    </>
  );
};

export default Referral;
