import axios from "axios";
import { config } from "../config/config";
import { getItem } from "../util/localStorage";

// const baseUrl = 'https://api.pineapple.dex.garena.co';

export const pineappleDexService = {
  login,
  userStatus,
  UserList,
  signOut,
  changePassword,
  SwapVolume,
  transaction_history,
  revenu,
  swapperToken,
  usersTransactionsList,
  individualTokenTransactions,
  getTokensList,
  getUserActivities,
  getContactUsData,
  getPopularPairsList,
  getSwapDetail,
  getTransactions,
  createToken,
  tokenEnableDisable,
  handleTokenToggleTopToken,
  getExistTokensList,
  updateToken,
  getLatestTokens,
  deleteLatestToken,
  getDashboardData,
  getLimitOrderData,
  getConfigurationData,
  updateConfigurationData,
  getNotificationList,
  getNotificationDeatil,
  resolveNotification,
  PromotionList,
  updateReferral,
  createReferral,
  getAllTokensList,
  getChains,
};

const headers = () => {
  let token = getItem("accessToken");
  return { headers: { Authorization: token } };
};

async function UserList(params) {
  return await axios.get(
    `${config.apiUrl}/admin/all-users?page=${params.page}&keyword=${params.keyword}&sort=${params.sort}&sortType=${params.sortType}&filter=${params.filter}`,
    headers()
  );
}

async function swapperToken(params) {
  return await axios.get(
    `${config.apiUrl}/admin/swapper-token/?page=${params.page}&search=${params.search}&filter=${params.filter}`,
    headers()
  );
}

async function SwapVolume(params) {
  return await axios.get(
    `${config.apiUrl}/admin/transactions?page=${params.page}&keyword=${params.keyword}&filter=${params.filter}&ft=${params.ft}&tt=${params.tt}&transactionType=${params.transactionType}`,
    headers()
  );
}

async function transaction_history(params) {
  return await axios.get(
    `${config.apiUrl}/swap-volumne/?page=${params.page}&search=${params.search}&filter=${params.filter}`,
    headers()
  );
}

async function revenu(params) {
  return await axios.get(
    `${config.apiUrl}/revenu/?page=${params.page}&search=${params.search}&filter=${params.filter}`,
    headers()
  );
}

async function userStatus(params, userId) {
  return await axios.put(
    `${config.apiUrl}/admin/user/status/${userId}`,
    params,
    headers()
  );
}

async function changePassword(data) {
  return await axios.post(`${config.apiUrl}/change-password`, data, headers());
}
async function login(data) {
  return await axios.post(`${config.apiUrl}/login/`, data);
}

async function signOut(data) {
  return await axios.post(`${config.apiUrl}/logout/`, data, headers());
}

async function usersTransactionsList(params) {
  return await axios.get(
    `${config.apiUrl}/admin/user/transaction/${params.userId}?page=${params.page}`,
    headers()
  );
}

async function individualTokenTransactions(params) {
  return await axios.get(
    `${config.apiUrl}/admin/transactions?page=${params.page}&token=${params.token}`,
    headers()
  );
}

async function getChains() {
  return await axios.get(`${config.apiUrl}/admin/chains`, headers());
}

async function getAllTokensList(params) {
  return await axios.get(
    `${config.apiUrl}/admin/tokens/${params.chainid}`,
    headers()
  );
}

async function getTokensList() {
  return await axios.get(`${config.apiUrl}/tokens`, headers());
}

async function getUserActivities(params) {
  return await axios.get(
    `${config.apiUrl}/admin//user/activity/${params.userId}?page=${params.page}`,
    headers()
  );
}

async function getContactUsData(params) {
  return await axios.get(
    `${config.apiUrl}/admin/contact-feed/list?page=${params.page}&keyword=${params.keyword}`,
    headers()
  );
}

async function getPopularPairsList(params) {
  return await axios.get(
    `${config.apiUrl}/admin/popular-pairs?page=${params.page}&keyword=${params.keyword}&filter=${params.filter}&sort=${params.sort}&sortType=${params.sortType}`,
    headers()
  );
}

async function getSwapDetail(params) {
  return await axios.get(
    `${config.apiUrl}/admin/transaction/${params.transactionId}`,
    headers()
  );
}

async function getTransactions(params) {
  let url = `${config.apiUrl}/admin/transactions`;
  let queryParams = [];
  for (const key in params) {
    queryParams.push(`${key}=${params[key]}`);
  }
  if (queryParams.length > 0) {
    url += "?" + queryParams.join("&");
  }
  return await axios.get(url, headers());
}

async function createToken(params) {
  return await axios.post(`${config.apiUrl}/admin/tokens`, params, headers());
}

async function tokenEnableDisable(params) {
  return await axios.post(
    `${config.apiUrl}/admin/enable-disable-token`,
    params,
    headers()
  );
}

async function handleTokenToggleTopToken(params) {
  return await axios.put(
    `${config.apiUrl}/admin/toggle-top-token`,
    params,
    headers()
  );
}

async function getExistTokensList(page, search) {
  return await axios.get(
    `${config.apiUrl}/admin/all-tokens?page=${page}&keyword=${search}`,
    headers()
  );
}

async function updateToken(tokenId, params) {
  return await axios.patch(
    `${config.apiUrl}/admin/tokens/${tokenId}`,
    params,
    headers()
  );
}

async function getLatestTokens() {
  return await axios.get(`${config.apiUrl}/admin/filtered-tokens`, headers());
}

async function deleteLatestToken(params) {
  return await axios.post(
    `${config.apiUrl}/admin/delete-tokens`,
    params,
    headers()
  );
}

async function getDashboardData(params) {
  let url = `${config.apiUrl}/admin/dashboard`;
  let queryParams = [];

  for (const key in params) {
    // Only include keys that have values which are not undefined, null, or empty strings
    if (
      params[key] !== undefined &&
      params[key] !== null &&
      params[key] !== ""
    ) {
      queryParams.push(`${key}=${params[key]}`);
    }
  }

  if (queryParams.length > 0) {
    url += "?" + queryParams.join("&");
  }

  return await axios.get(url, headers());
}

/* get configruation */
async function getConfigurationData() {
  return await axios.get(
    `${config.apiUrl}/admin/event-categorization`,
    headers()
  );
}

/* Add/Update configuration Data  */
async function updateConfigurationData(params, configruationDetail) {
  return await axios.put(
    `${config.apiUrl}/admin/event-categorization/${configruationDetail._id}`,
    params,
    headers()
  );
}

/* get notification list */
// async function getNotificationList(PAGE, priority, status,sortOrder) {
//     return await axios.get(`${config.apiUrl}/admin/event-alerts?page=${PAGE}&priority=${priority}&isRead=${status}&sortOrder=${sortOrder}`, headers())
// }

async function getNotificationList(PAGE, priority, status, sortOrder) {
  const queryParams = {};

  // Add keys to queryParams only if they have valid values
  // if (PAGE) queryParams.page = PAGE;
  if (priority) queryParams.priority = priority;
  // if (status) queryParams.isRead = status;
  if (sortOrder) queryParams.sortOrder = sortOrder;

  // Construct the query string
  const queryString = Object.keys(queryParams)
    .map((key) => `${key}=${encodeURIComponent(queryParams[key])}`)
    .join("&");

  // Make the API request with the constructed query string
  // return await axios.get(`${config.apiUrl}/admin/event-alerts?page=${PAGE}&${queryString}`, headers());

  return await axios.get(
    `${config.apiUrl}/admin/event-alerts?page=${PAGE}&isRead=${status}&${queryString}`,
    headers()
  );
}

/* get notification particular detail */
async function getNotificationDeatil(params) {
  return await axios.get(
    `${config.apiUrl}/admin/event-alert/${params?.userId}`,
    headers()
  );
}

async function resolveNotification(params) {
  return await axios.post(
    `${config.apiUrl}/admin/event-alert/resolve`,
    params,
    headers()
  );
}

async function getLimitOrderData(params) {
  let url = `${config.apiUrl}/admin/limit-order-transactions`;
  let queryParams = [];
  for (const key in params) {
    queryParams.push(`${key}=${params[key]}`);
  }
  if (queryParams.length > 0) {
    url += "?" + queryParams.join("&");
  }
  return await axios.get(url, headers());
}

// Referral Management APIs
async function PromotionList(params) {
  return await axios.get(
    `${config.apiUrl}/admin/referrals?page=${params.page}&keyword=${params.keyword}&sort=${params.sort}&sortType=${params.sortType}&filter=${params.filter}`,
    headers()
  );
}

async function updateReferral(data) {
  return await axios.put(
    `${config.apiUrl}/admin/referrals/${data._id}`,
    data,
    headers()
  );
}

async function createReferral(data) {
  return await axios.post(`${config.apiUrl}/admin/referrals`, data, headers());
}
